<template>
  <div class="card card-icon mb-4">
    <div class="row no-gutters">
      <!-- Icon -->
      <div
        class="d-none
            d-md-flex
            col-auto
            card-icon-aside
            bg-primary
            text-white"
      >
        <i data-feather="layers"></i>
      </div>

      <div class="col">
        <div class="card-body py-4">
          <h3 class="card-title">
            <span :class="isEditingResource ? 'text-danger' : ''">{{ titleForm }}</span>
          </h3>

          <div class="card-text">
            <FormulateForm
              ref="catalogoFuentesFinanciamientoForm"
              @submit="submit"
              #default="{ hasErrors, isLoading }"
            >
              <b-row>
                <!-- Periodo fiscal -->
                <b-col cols="12" md="12" lg="6">
                  <x-form-group title="Periodo fiscal" required>
                    <check-authorization :requiresAuthorizations="['listar periodos fiscales']">
                      <x-select
                        v-model="formValues.periodo_fiscal_id"
                        dispatchPath="fiscalPeriodsModule/getFiscalPeriods"
                        getterListPath="fiscalPeriodsModule/getAllFiscalPeriods"
                        :formatListWith="['id', 'nombre']"
                        no-prepend
                        no-validate
                      ></x-select>
                    </check-authorization>
                  </x-form-group>
                </b-col>

                <!-- Clave -->
                <b-col cols="12" md="12" lg="6">
                  <x-form-group title="Clave" required>
                    <FormulateInput
                      v-model="formValues.clave"
                      :disabled="isLoading"
                      placeholder="Ingresa la clave de la fuente de financiamiento"
                      validation="bail|required"
                      :validation-messages="{
                        required: 'La clave de la fuente de financiamiento es requerida'
                      }"
                    />
                  </x-form-group>
                </b-col>
              </b-row>

              <b-row>
                <!-- Nombre -->
                <b-col cols="12" md="12" lg="6">
                  <x-form-group title="Nombre" required>
                    <FormulateInput
                      v-model="formValues.nombre"
                      :disabled="isLoading"
                      placeholder="Ingresa el nombre de la fuente de financiamiento"
                      validation="bail|required"
                      :validation-messages="{
                        required: 'El nombre de la fuente de financiamiento es requerido'
                      }"
                    />
                  </x-form-group>
                </b-col>

                <!-- Estatus -->
                <b-col cols="12" md="12" lg="6">
                  <x-form-group title="Estatus">
                    <b-form-select
                      :value="formValues.estatus.value"
                      v-model="formValues.estatus"
                      :options="[
                        { text: 'Activo', value: true },
                        { text: 'Inactivo', value: false }
                      ]"
                    ></b-form-select>
                  </x-form-group>
                </b-col>
              </b-row>

              <b-row>
                <!-- Techo financiero para la fuente de financiamiento -->
                <b-col cols="12" md="12" lg="6">
                  <x-form-group title="Techo financiero" required>
                    <FormulateInput
                      v-model="formValues.techo_financiero"
                      inputmode="numeric"
                      pattern="[0-9]*"
                      placeholder="Ingresa la cantidad del techo financiero a asignar"
                      outer-class="form-group"
                      input-class="form-control no-border"
                      :disabled="isLoading"
                      help="Ingresa únicamente dígitos con punto decimal opcional."
                      error-behavior="live"
                      validation="number|min:0|matches:/[+-]?([0-9]*[.])?[0-9]+/"
                      :validation-messages="{
                        number: 'Únicamente se pueden ingresar dígitos.',
                        min: 'Únicamente se pueden asignar números positivos.',
                        matches: 'La información ingresada no es aceptable.'
                      }"
                    />
                  </x-form-group>
                </b-col>

                <!-- Formato -->
                <b-col cols="12" md="12" lg="6">
                  <x-form-group title="Formato">
                    <b-form-input
                      readonly
                      :value="$formatNumber(formValues.techo_financiero)"
                    ></b-form-input>
                  </x-form-group>
                </b-col>
              </b-row>

              <!-- Enviar para crear o actualizar -->
              <x-form-footer-buttons
                :disabled="hasErrors || isLoading"
                :loading="isLoading"
                :isEditing="isEditingResource"
                :isCreating="!isEditingResource"
                @on-cancel="resetForm"
                @on-click-create="submit"
                @on-click-update="submit"
              ></x-form-footer-buttons>
            </FormulateForm>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

const formValues = () => ({
  nombre: '',
  clave: '',
  techo_financiero: 0,
  estatus: '',
  periodo_fiscal_id: ''
})

export default {
  name: 'FuenteFinanciamientoForm',

  data () {
    return {
      formValues: formValues()
    }
  },

  computed: {
    ...mapGetters('fuenteFinanciamientoModule', ['isEditingResource', 'getCurrentEditing']),

    ...mapGetters('fiscalPeriodsModule', ['getFiscalPeriodsForForms']),

    titleForm () {
      return this.isEditingResource
        ? `Editando fuente de financiamiento: ${this.getCurrentEditing.nombre}`
        : 'Crear fuente de financiamiento'
    }
  },

  watch: {
    isEditingResource: {
      deep: true,
      immediate: true,
      handler (value) {
        if (value) {
          this.formValues = this.getCurrentEditing
          this.formValues.techo_financiero = parseInt(this.getCurrentEditing.techo_financiero)
        }
      }
    }
  },

  methods: {
    emitOnHideForm () {
      this.$emit('hide-form')
    },

    emitOnSubmitted () {
      this.$emit('submitted')
    },

    async submit () {
      const { error, message } = this.isEditingResource
        ? await this.update(this.formValues)
        : await this.create(this.formValues)

      if (error) return this.$notify({ error, message }, 'Envío de formulario')

      this.emitOnSubmitted()
      this.resetForm()
    },

    async create (form) {
      return await this.$store.dispatch('fuenteFinanciamientoModule/create', form)
    },

    async update (form) {
      return await this.$store.dispatch('fuenteFinanciamientoModule/updateById', {
        id: form.id,
        payload: form
      })
    },

    resetForm () {
      document.activeElement.blur()
      this.$store.commit('fuenteFinanciamientoModule/setCurrentResource', null)
      this.formValues = formValues()
      if (this.$refs.catalogoFuentesFinanciamientoForm) {
        this.$refs.catalogoFuentesFinanciamientoForm.hideErrors()
        this.emitOnHideForm()
      }
      window.scrollTo(0, 0)
    }
  }
}
</script>
