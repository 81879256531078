<template>
  <div>
    <!-- Modal para mostrar la asignación del techo financiero por capítulos -->
    <x-modal
      v-model="showModal"
      title="Editar techo financiero por capítulo"
      with-filter
      #default="{ filter }"
    >
      <fuente-financiamiento-capitulos :update-data="updateData" :filter="filter" />
    </x-modal>
    <!-- END: Modal para mostrar el desglose de los importes por mes -->

    <!-- Componente para filtrar por el periodo fiscal activo -->
    <x-select-periodo-fiscal @input="onChangePeriodoFiscal" class="mb-3" />

    <b-table
      :fields="fields"
      :items="getList"
      striped
      bordered
      responsive
      hover
      stacked="sm"
      :busy="!isListAvailable"
    >
      <template #table-busy>
        <div class="text-center my-2" style="min-height: 25vh">
          <b-spinner class="align-middle mr-3"></b-spinner>
          <strong>Cargando...</strong>
        </div>
      </template>

      <!-- Techo financiero -->
      <template #cell(techo_financiero)="row">
        <div class="cell--center">
          <b-badge v-if="row.item.clave==='GLOBAL'">{{$formatNumber(row.item.techo_financiero)}}</b-badge>
          <b-badge v-else-if="!row.item.techo_financiero" variant="danger">Sin asignar</b-badge>
          <b-button v-else @click="showFormModal(row.item)" variant="primary" size="sm">
            <span class="text-light">Editar:</span>
            <span class="mx-2">{{ $formatNumber(row.item.techo_financiero) }}</span>
            <b-icon class="text-light" icon="arrows-fullscreen" aria-hidden="true"></b-icon>
          </b-button>
        </div>
      </template>

      <template #cell(estatus)="row">
        <div class="cell--center">
          <b-badge :variant="$getStatusVariant(row.item.estatus)">
            {{ $getStatusText(row.item.estatus) }}
          </b-badge>
        </div>
      </template>

      <template #cell(periodo_fiscal)="row">
        <div class="cell--center">
          <b-badge>
            {{ row.item.periodo_fiscal.nombre }}
          </b-badge>
        </div>
      </template>

      <template #cell(acciones)="row">
        <div class="cell--center">
          <u-d-controls
            :id="row.item.id"
            editPath="fuenteFinanciamientoModule/getToEdit"
            deletePath="fuenteFinanciamientoModule/deleteById"
            :editPermissions="[permissions.update]"
            :deletePermissions="[permissions.delete]"
            @on-got-item="$up"
            @on-deleted="onDeleted"
          />
        </div>
      </template>
    </b-table>
    <div v-if="isListAvailable">
      <paginator
        aria-controls="fuentesFinanciamientoTable"
        :disabled="!isListAvailable"
        show-path="fuenteFinanciamientoModule/getAll"
        :resource="$store.state.fuenteFinanciamientoModule.list.resource"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import FuenteFinanciamientoCapitulos from '@/components/Catalogos/FuentesFinanciamiento/FuenteFinanciamientoCapitulos'

export default {
  name: 'FuenteFinanciamientoList',

  props: {
    permissions: {
      type: Object,
      required: true
    }
  },

  async created () {
    const active = await this.$getPeriodoFiscalActivo()

    this.periodo_fiscal_id = active.id
  },

  components: {
    FuenteFinanciamientoCapitulos
  },

  data () {
    return {
      showModal: false,
      updateData: null,
      fields: [
        { key: 'clave', label: 'Clave' },
        { key: 'nombre', label: 'Nombre' },
        { key: 'periodo_fiscal', label: 'Periodo fiscal' },
        { key: 'techo_financiero', label: 'Techo financiero', sortable: true },
        { key: 'estatus', label: 'Estatus' },
        { key: 'acciones', label: 'Acciones' }
      ],

      periodo_fiscal_id: null
    }
  },

  computed: {
    ...mapGetters('fuenteFinanciamientoModule', ['getList', 'isListAvailable'])
  },

  methods: {
    emitOnInput () {
      this.$emit('input', this.periodo_fiscal_id)
    },

    showFormModal (item) {
      this.updateData = {
        fuente_financiamiento_id: item.id
      }
      this.showModal = true
    },

    onChangePeriodoFiscal (id) {
      this.periodo_fiscal_id = id

      this.emitOnInput()
    },

    onDeleted ({ error }) {
      if (!error) this.emitOnInput()
    }
  }
}
</script>
